// src/pages/Home.js

import React, { useEffect, useState } from "react";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { InView } from "react-intersection-observer";
import Header from "../components/Header";
import Hero from "../components/Hero";
import Services from "../components/Servicios";
import Portfolio from "../components/Portfolio";
import About from "../components/About";
import Testimonials from "../components/Testimonials";
import ContactForm from "../components/ContactForm";
// import Footer from "../components/Footer";

function LazySection({ children }) {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (isVisible) {
      // Bloquea el scroll cuando la sección comienza a cargarse
      document.body.style.overflow = "hidden";
    }

    // Habilita el scroll después de que la sección se haya renderizado
    const unblockScroll = () => {
      document.body.style.overflow = "auto";
    };

    // Ejecuta unblockScroll en el próximo ciclo de evento para asegurar que el renderizado haya ocurrido
    if (isVisible) {
      setTimeout(unblockScroll, 0);
    }

    // Limpia el estilo en caso de desmontaje
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isVisible]);

  return (
    <InView
      as="div"
      triggerOnce={true}
      threshold={0.1}
      onChange={(inView) => {
        if (inView) {
          setIsVisible(true); // Se activa solo cuando la sección está en el viewport
        }
      }}>
      <div
        className="section"
        style={{
          visibility: isVisible ? "visible" : "hidden",
          minHeight: "100vh", // Ocupa toda la pantalla
        }}>
        {isVisible ? children : null} {/* Renderiza solo si es visible */}
      </div>
    </InView>
  );
}

function Home() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>UltharKey - Servicios de Desarrollo Web</title>
        <meta
          name="description"
          content="Servicios a medida, optimización SEO y más."
        />
      </Helmet>

      {/* Configura el contenedor principal para scroll snapping */}
      <div
        style={{
          scrollSnapType: "y mandatory", // Forzar el scroll vertical de snapping
          overflowY: "scroll",
          height: "100vh", // Cada sección ocupará la pantalla completa
        }}>
        {/* Renderiza el Header al inicio de la página */}
        <Header />

        {/* Utiliza LazySection para cargar cada componente bajo demanda */}
        <LazySection>
          <Hero />
        </LazySection>
        <LazySection>
          <Services />
        </LazySection>
        <LazySection>
          <Portfolio />
        </LazySection>
        <LazySection>
          <About />
        </LazySection>
        <LazySection>
          <ContactForm />
        </LazySection>
        <LazySection>
          <Testimonials />
        </LazySection>

        {/* Footer, que se carga sin lazy loading */}
        {/* <Footer /> */}
      </div>
    </HelmetProvider>
  );
}

export default Home;
