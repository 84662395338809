// src/components/ContactForm.js

import React, { useState } from "react";
import {
  Container,
  Typography,
  TextField,
  Button,
  Box,
  Alert,
} from "@mui/material";

function ContactForm() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validación básica de campos
    if (!formData.name || !formData.email || !formData.message) {
      setError("Por favor completa todos los campos.");
      return;
    }

    // Simulación de envío de formulario
    setSubmitted(true);
    setError("");

    // Resetear el formulario después de enviar
    setFormData({ name: "", email: "", message: "" });
  };

  return (
    <Container id="contact" sx={{ py: 8 }}>
      <Typography variant="h4" component="h2" gutterBottom align="center">
        Contáctanos
      </Typography>
      <Typography variant="body1" align="center" paragraph>
        ¿Tienes alguna pregunta o deseas trabajar con nosotros? Llena el
        formulario y te contactaremos pronto.
      </Typography>

      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{ maxWidth: "600px", margin: "0 auto", mt: 4 }}>
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}
        {submitted && (
          <Alert severity="success" sx={{ mb: 2 }}>
            ¡Gracias! Nos pondremos en contacto contigo pronto.
          </Alert>
        )}

        <TextField
          label="Nombre"
          name="name"
          value={formData.name}
          onChange={handleChange}
          fullWidth
          margin="normal"
          required
          sx={{
            "& .MuiOutlinedInput-root": {
              "& fieldset": { borderColor: "#FFFFFF" },
              "&:hover fieldset": { borderColor: "#FFFFFF" },
              "&.Mui-focused fieldset": { borderColor: "#FFFFFF" },
            },
            "& .MuiInputLabel-root": { color: "#B3B3B3" },
            "& .MuiInputBase-input": { color: "#FFFFFF" },
          }}
        />

        <TextField
          label="Correo Electrónico"
          name="email"
          type="email"
          value={formData.email}
          onChange={handleChange}
          fullWidth
          margin="normal"
          required
          sx={{
            "& .MuiOutlinedInput-root": {
              "& fieldset": { borderColor: "#FFFFFF" },
              "&:hover fieldset": { borderColor: "#FFFFFF" },
              "&.Mui-focused fieldset": { borderColor: "#FFFFFF" },
            },
            "& .MuiInputLabel-root": { color: "#B3B3B3" },
            "& .MuiInputBase-input": { color: "#FFFFFF" },
          }}
        />

        <TextField
          label="Mensaje"
          name="message"
          value={formData.message}
          onChange={handleChange}
          fullWidth
          margin="normal"
          multiline
          rows={4}
          required
          sx={{
            "& .MuiOutlinedInput-root": {
              "& fieldset": { borderColor: "#B3B3B3" },
              "&:hover fieldset": { borderColor: "#FFFFFF" },
              "&.Mui-focused fieldset": { borderColor: "#FFFFFF" },
            },
            "& .MuiInputLabel-root": { color: "#B3B3B3" },
            "& .MuiInputBase-input": { color: "#FFFFFF" },
          }}
        />

        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          sx={{
            mt: 3,
            backgroundColor: "#B3B3B3",
            color: "#1F1F1F",
            "&:hover": {
              backgroundColor: "#FFFFFF",
            },
          }}>
          Enviar Mensaje
        </Button>
      </Box>
    </Container>
  );
}

export default ContactForm;
