// src/pages/Project/ProjectDetails.js

import React from "react";
import { useParams, Link } from "react-router-dom";
import {
  Container,
  Typography,
  Box,
  Button,
  Grid,
  Card,
  CardMedia,
  CardContent,
} from "@mui/material";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const projectData = {
  tattoostudio: {
    title: "Landing Page para Estudio de Tatuajes",
    description:
      "Desarrollo de una landing page optimizada para un estudio de tatuajes, diseñada para atraer clientes y mostrar el portafolio de trabajos del estudio. Incluye galerías de imágenes, testimonios de clientes y una sección de contacto.",
    technologies: ["React", "Material UI", "Node.js", "Express", "Cloudinary"],
    images: ["/path/to/tattoo-image1.jpg", "/path/to/tattoo-image2.jpg"],
    liveLink: "https://example.com/tattoo-studio",
    testimonials: [
      {
        name: "Carlos Ramírez",
        feedback:
          "El sitio web realmente captura la esencia de nuestro estudio. Fácil de navegar y nuestros clientes lo adoran.",
      },
      {
        name: "María López",
        feedback:
          "Gracias a la nueva landing page, hemos visto un aumento en clientes que nos contactan a través del formulario.",
      },
    ],
  },

  // Agrega más proyectos aquí
};

function ProjectDetails() {
  const { projectId } = useParams();
  const project = projectData[projectId];

  if (!project) {
    return <Typography variant="h6">Proyecto no encontrado</Typography>;
  }

  return (
    <>
      <Header />

      <Container sx={{ py: 8, overflow: "hidden" }}>
        <Typography variant="h4" component="h1" gutterBottom align="center">
          {project.title}
        </Typography>

        <Typography variant="body1" paragraph>
          {project.description}
        </Typography>

        <Box sx={{ my: 4 }}>
          <Typography variant="h6">Tecnologías Utilizadas</Typography>
          <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap", mt: 1 }}>
            {project.technologies.map((tech, index) => (
              <Typography key={index} variant="body2" color="textSecondary">
                {tech}
              </Typography>
            ))}
          </Box>
        </Box>

        <Grid container spacing={2} sx={{ mb: 4 }}>
          {project.images.map((image, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <Card sx={{ overflow: "hidden" }}>
                <CardMedia
                  component="img"
                  height="200"
                  image={image}
                  alt={`Screenshot ${index + 1}`}
                />
              </Card>
            </Grid>
          ))}
        </Grid>

        {project.liveLink && (
          <Button
            variant="contained"
            color="primary"
            href={project.liveLink}
            target="_blank"
            rel="noopener"
            sx={{ mb: 4 }}>
            Ver Sitio en Vivo
          </Button>
        )}

        {project.testimonials && project.testimonials.length > 0 && (
          <Box sx={{ mt: 4 }}>
            <Typography variant="h6">Testimonios del Proyecto</Typography>
            {project.testimonials.map((testimonial, index) => (
              <Card key={index} sx={{ my: 2, p: 2, overflow: "hidden" }}>
                <CardContent>
                  <Typography variant="body1">
                    "{testimonial.feedback}"
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    - {testimonial.name}
                  </Typography>
                </CardContent>
              </Card>
            ))}
          </Box>
        )}

        <Box sx={{ textAlign: "center", mt: 4 }}>
          <Button component={Link} to="/" variant="outlined">
            Volver al Inicio
          </Button>
        </Box>
      </Container>

      <Footer />
    </>
  );
}

export default ProjectDetails;
