// src/components/About.js

import React from "react";
import { Container, Typography, Box } from "@mui/material";

function About() {
  return (
    <Container
      id="about"
      maxWidth="md" // Limita el ancho máximo en pantallas grandes para mejorar la legibilidad
      sx={{
        py: 8,
        overflow: "hidden", // Para evitar que el ResizeObserver se active innecesariamente
      }}>
      <Typography variant="h4" component="h2" gutterBottom align="center">
        Sobre Nuestra Empresa
      </Typography>

      <Box
        sx={{
          textAlign: "center",
          maxWidth: "800px",
          margin: "0 auto",
          mt: 4,
          overflow: "hidden", // Evita posibles problemas con redimensionamiento
        }}>
        <Typography variant="body1" paragraph>
          En <strong>Mi Empresa</strong>, nos especializamos en ofrecer
          soluciones de desarrollo web a medida para ayudar a las empresas a
          alcanzar sus objetivos en línea. Con un equipo de profesionales
          experimentados, trabajamos de cerca con nuestros clientes para crear
          sitios web optimizados, funcionales y atractivos.
        </Typography>

        <Typography variant="body1" paragraph>
          Nuestra misión es brindar servicios de alta calidad que impulsen el
          crecimiento digital de nuestros clientes. Desde el diseño inicial
          hasta la implementación final, aseguramos que cada proyecto refleje la
          visión y valores únicos de cada empresa.
        </Typography>

        <Typography variant="body1" paragraph>
          Estamos comprometidos con la innovación y la excelencia en cada
          proyecto que emprendemos, ofreciendo soluciones tecnológicas avanzadas
          y soporte continuo.
        </Typography>
      </Box>
    </Container>
  );
}

export default About;
