// src/components/Portfolio.js

import React from "react";
import {
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Button,
} from "@mui/material";
import { Link } from "react-router-dom";

const projects = [
  {
    title: "Landing page",
    description: "Descripción breve del proyecto 1.",
    image: "/path/to/image1.jpg",
    link: "/projects/tattoostudio",
  },
  {
    title: "Proyecto 2",
    description: "Descripción breve del proyecto 2.",
    image: "/path/to/image2.jpg",
    link: "/projects/proyecto-2",
  },
  {
    title: "Proyecto 3",
    description: "Descripción breve del proyecto 3.",
    image: "/path/to/image3.jpg",
    link: "/projects/proyecto-3",
  },
  // Añadir más proyectos si es necesario
];

function Portfolio() {
  return (
    <Container id="portfolio" sx={{ py: 8, overflow: "hidden" }}>
      <Typography variant="h4" component="h2" gutterBottom align="center">
        Portafolio
      </Typography>
      <Grid container spacing={4}>
        {projects.map((project, index) => (
          <Grid item key={index} xs={12} sm={6} md={4}>
            <Card
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
              }}>
              <CardMedia
                component="img"
                height="140"
                image={project.image}
                alt={project.title}
              />
              <CardContent>
                <Typography variant="h6" component="h3" gutterBottom>
                  {project.title}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {project.description}
                </Typography>
              </CardContent>
              <Button
                component={Link}
                to={project.link}
                variant="contained"
                color="primary"
                sx={{ m: 2, mt: "auto" }}>
                Ver Proyecto
              </Button>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default Portfolio;
