// src/components/Services.js

import React from "react";
import {
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
  CardMedia,
} from "@mui/material";
import WebIcon from "@mui/icons-material/Web";
import SpeedIcon from "@mui/icons-material/Speed";
import SecurityIcon from "@mui/icons-material/Security";

const services = [
  {
    title: "Desarrollo Web",
    description:
      "Creación de sitios web personalizados y optimizados para dispositivos móviles.",
    icon: <WebIcon fontSize="large" color="secondary" />, // Cambiado a color secundario
  },
  {
    title: "Optimización SEO",
    description:
      "Mejora de posicionamiento en buscadores para atraer más tráfico a tu sitio web.",
    icon: <SpeedIcon fontSize="large" color="secondary" />, // Cambiado a color secundario
  },
  {
    title: "Seguridad Web",
    description:
      "Implementación de medidas de seguridad para proteger tu sitio y tus datos.",
    icon: <SecurityIcon fontSize="large" color="secondary" />, // Cambiado a color secundario
  },
];

function Services() {
  return (
    <Container id="services" sx={{ py: 8, overflow: "hidden" }}>
      <Typography variant="h4" component="h2" gutterBottom align="center">
        Nuestros Servicios
      </Typography>
      <Grid container spacing={4}>
        {services.map((service, index) => (
          <Grid item key={index} xs={12} sm={6} md={4}>
            <Card
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                overflow: "hidden", // Evita redimensionamiento innecesario
              }}>
              <CardMedia sx={{ mt: 2 }}>{service.icon}</CardMedia>
              <CardContent>
                <Typography
                  variant="h6"
                  component="h3"
                  gutterBottom
                  align="center">
                  {service.title}
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  align="center">
                  {service.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default Services;
