// src/components/Footer.js

import React from "react";
import { Container, Typography, Box, Link, IconButton } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";

function Footer() {
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: "#282c34",
        color: "white",
        py: 2,
        textAlign: "center",
      }}>
      <Container
        maxWidth="md"
        sx={{
          textAlign: "center",
          overflow: "hidden", // Evita redimensionamiento innecesario
        }}>
        {/* Nombre o mensaje de la empresa */}
        <Typography variant="h6" gutterBottom>
          Mi Empresa
        </Typography>

        {/* Enlaces a redes sociales */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: 2,
            mb: 2,
            overflow: "hidden", // Evita redimensionamiento en contenedor de íconos
          }}>
          <IconButton
            component="a"
            href="https://facebook.com"
            target="_blank"
            rel="noopener"
            color="inherit">
            <FacebookIcon />
          </IconButton>
          <IconButton
            component="a"
            href="https://twitter.com"
            target="_blank"
            rel="noopener"
            color="inherit">
            <TwitterIcon />
          </IconButton>
          <IconButton
            component="a"
            href="https://linkedin.com"
            target="_blank"
            rel="noopener"
            color="inherit">
            <LinkedInIcon />
          </IconButton>
          <IconButton
            component="a"
            href="https://instagram.com"
            target="_blank"
            rel="noopener"
            color="inherit">
            <InstagramIcon />
          </IconButton>
        </Box>

        {/* Enlaces a términos y condiciones, y política de privacidad */}
        <Typography variant="body2">
          <Link href="/terms" color="inherit" underline="hover">
            Términos y Condiciones
          </Link>{" "}
          |{" "}
          <Link href="/privacy" color="inherit" underline="hover">
            Política de Privacidad
          </Link>
        </Typography>

        {/* Copyright */}
        <Typography variant="body2" sx={{ mt: 2 }}>
          &copy; {new Date().getFullYear()} Mi Empresa. Todos los derechos
          reservados.
        </Typography>
      </Container>
    </Box>
  );
}

export default Footer;
