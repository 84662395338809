// src/components/Testimonials.js

import React from "react";
import {
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
  Avatar,
  Box,
} from "@mui/material";
import Footer from "./Footer";

const testimonials = [
  {
    name: "Juan Pérez",
    feedback:
      "El equipo de Mi Empresa fue increíble. Nos ayudaron a crear un sitio web fantástico que ha aumentado nuestro tráfico y ventas.",
    avatar: "/path/to/avatar1.jpg",
  },
  {
    name: "Ana López",
    feedback:
      "Excelentes servicios y atención al detalle. Recomiendo completamente a Mi Empresa para soluciones de desarrollo web.",
    avatar: "/path/to/avatar2.jpg",
  },
  {
    name: "Carlos Díaz",
    feedback:
      "Muy satisfecho con los resultados. El sitio web es rápido, seguro y muy atractivo. Excelente trabajo de Mi Empresa.",
    avatar: "/path/to/avatar3.jpg",
  },
];

function Testimonials() {
  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <Container id="testimonials" sx={{ py: 15, overflow: "hidden", flex: 1 }}>
        <Typography variant="h4" component="h2" gutterBottom align="center">
          Lo Que Dicen Nuestros Clientes
        </Typography>
        <Grid container spacing={4}>
          {testimonials.map((testimonial, index) => (
            <Grid item key={index} xs={12} sm={6} md={4}>
              <Card
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                  padding: 2,
                  overflow: "hidden",
                }}>
                <Avatar
                  src={testimonial.avatar}
                  alt={testimonial.name}
                  sx={{ width: 80, height: 80, mb: 2 }}
                />
                <CardContent>
                  <Typography variant="h6" component="h3" gutterBottom>
                    {testimonial.name}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    "{testimonial.feedback}"
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
      <Footer />
    </Box>
  );
}

export default Testimonials;
