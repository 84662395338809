// src/components/Header.js

import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link as RouterLink } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";

function Header() {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const menuItems = [
    { text: "Inicio", link: "/" },
    { text: "Servicios", link: "services" },
    { text: "Portafolio", link: "portfolio" },
    { text: "Nosotros", link: "about" },
    { text: "Contacto", link: "contact" },
  ];

  return (
    <>
      <AppBar position="fixed" color="primary" sx={{ overflow: "hidden" }}>
        <Toolbar>
          {/* Menú de hamburguesa, visible solo en pantallas pequeñas */}
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
            sx={{
              display: { xs: "block", md: "none" },
              mr: 2,
            }}>
            <MenuIcon />
          </IconButton>

          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <RouterLink
              to="/"
              style={{
                color: "inherit",
                textDecoration: "none",
                display: "flex",
                alignItems: "center",
              }}>
              <img
                src="imagenes/logo_llave.png" // Cambia esta ruta al lugar donde tengas guardado el logo
                alt="Logo"
                style={{
                  height: "80px",
                  filter: "brightness(0) saturate(100%) invert(1)",
                }} // Ajusta la altura del logo según necesites
              />
              <img
                src="imagenes/UltharKeyFont.png" // Cambia esta ruta al lugar donde tengas guardado el logo
                alt="Logo"
                style={{
                  height: "40px",
                  filter: "brightness(0) saturate(100%) invert(1)",
                }} // Ajusta la altura del logo según necesites
              />
            </RouterLink>
          </Typography>

          {/* Menú de navegación, visible solo en pantallas medianas y grandes */}
          <Box sx={{ display: { xs: "none", md: "flex" }, gap: 1 }}>
            {menuItems.map((item, index) =>
              item.link.startsWith("/") ? (
                <Button
                  key={index}
                  component={RouterLink}
                  to={item.link}
                  sx={{
                    color: "inherit",
                    backgroundColor: "transparent",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "rgba(255, 255, 255, 0.1)", // Sutil efecto de hover
                    },
                  }}>
                  {item.text}
                </Button>
              ) : (
                <Button
                  key={index}
                  component={ScrollLink}
                  to={item.link}
                  smooth={true}
                  duration={500}
                  offset={-64} // Compensación para el header fijo
                  sx={{
                    color: "inherit",
                    backgroundColor: "transparent",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "rgba(255, 255, 255, 0.1)", // Sutil efecto de hover
                    },
                  }}>
                  {item.text}
                </Button>
              )
            )}
          </Box>
        </Toolbar>
      </AppBar>

      {/* Drawer para menú de hamburguesa en pantallas pequeñas */}
      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}>
          <List>
            {menuItems.map((item, index) =>
              item.link.startsWith("/") ? (
                <ListItem key={index} disablePadding>
                  <ListItemButton
                    component={RouterLink}
                    to={item.link}
                    onClick={toggleDrawer(false)} // Cierra el Drawer al hacer clic
                  >
                    <ListItemText primary={item.text} />
                  </ListItemButton>
                </ListItem>
              ) : (
                <ListItem key={index} disablePadding>
                  <ListItemButton
                    component={ScrollLink}
                    to={item.link}
                    smooth={true}
                    duration={500}
                    offset={-64} // Compensación para el header fijo
                    onClick={toggleDrawer(false)} // Cierra el Drawer al hacer clic
                  >
                    <ListItemText primary={item.text} />
                  </ListItemButton>
                </ListItem>
              )
            )}
          </List>
        </Box>
      </Drawer>

      {/* Toolbar vacío para compensar el header fijo en pantallas pequeñas */}
      <Toolbar />
    </>
  );
}

export default Header;
