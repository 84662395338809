// src/components/Hero.js

import React from "react";
import { Container, Typography, Button, Box } from "@mui/material";

function Hero() {
  return (
    <Box
      sx={{
        backgroundImage: `url('/path/to/your/background-image.jpg')`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "white",
        textAlign: "center",
        padding: 4,
        overflow: "hidden", // Evita redimensionamiento innecesario
      }}>
      <Container maxWidth="md" sx={{ overflow: "hidden" }}>
        {/* <Typography variant="h2" component="h1" gutterBottom>
          Bienvenid@
        </Typography> */}
        <img
          src="imagenes/logo_completo.png"
          alt="logo"
          style={{
            height: "400px",
            filter: "brightness(0) saturate(100%) invert(1)",
          }}
        />
        <Typography variant="h5" component="p" gutterBottom>
          Servicios de desarrollo web a medida, optimización SEO y más.
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          size="large"
          href="#contact"
          sx={{ mt: 3 }}>
          Contáctanos
        </Button>
      </Container>
    </Box>
  );
}

export default Hero;
